import React from 'react'

import Layout from '../../components/Layout'
import Therapies from '../../components/Therapies'

const TherapyIndexPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <Therapies />
        </div>
      </div>
    </section>
  </Layout>
);

export default TherapyIndexPage;